@media screen and (max-width: 1680px){



}
@media screen and (max-width: 1366px){


}

@media screen and (max-width: 1199px){
.header .header-wrap{ padding: 0 0px;}
.stellarnav>ul>li {margin-right: 23px;}
#banner-slider .owl-nav button.owl-prev{ left: 8px; }
#banner-slider .owl-nav button.owl-next{ right: 8px; }
.better-together-sec .better-together-text .better-together-text-wrap{
    max-width: 465px;
    padding: 0 10% 0 0;
}
}

@media screen and (max-width: 991px){
.banner .desc .desc-text h1 {font-size: 30px;}
#banner-slider .owl-nav button.owl-prev{ display: none; }
#banner-slider .owl-nav button.owl-next{ display: none; }
.services-sec .services-box .services-panel{ flex-direction: column;  justify-content:center; }
.services-sec .services-box .services-panel .icon-tham{ margin: 0 0 15px; }
.services-sec .services-box{ padding: 20px 15px; }
.services-sec .services-box .info-box{ text-align: center; }
.footer .ft-social ul li{ margin: 0 3px 0 0; }
.stellarnav.mobile.dark ul{ background:#fff !important; }
.stellarnav.dark .icon-close:after, .stellarnav.dark .icon-close:before{ border-color:#000; }
.stellarnav.dark .menu-toggle span.bars span{ background:#000; }
#partner-slider .owl-nav button{ display:none; }
}

@media screen and (max-width: 960px){

}

@media screen and (max-width: 767px){
.banner .desc .desc-text h1 {
    font-size: 24px;
    letter-spacing: normal;
}
.banner .desc .desc-text a{ font-size:14px; padding: 13px 18px;}
.banner .desc .desc-text .desc-btn a:last-child {
    margin-left: 10px;
}
.banner .desc .desc-text p{ font-size:16px; padding-bottom:15px;}
.partner-sec h2{ font-size:30px; margin-bottom: 30px; }
.banner .desc {
    padding: 20px 0;
    margin: 0;
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    transform: inherit;
    background: #011c39;
}
.footer .ft-desc{ margin-bottom:25px; }
.footer{ padding: 30px 0 20px; }
.footer .ft-box{ margin-bottom:20px; }
.innerpage_wrapper img{ width: 100%; margin: 0 0 15px; }
}

@media screen and (max-width: 575px){

}

@media screen and (max-width: 430px){

}
* {
	padding: 0;
	margin: 0;
}

body {
	padding: 0;
	margin: 0;
	font-family: 'Outfit', sans-serif;
}

a,
a:hover,
a:focus {
	text-decoration: none;
	padding: 0;
	margin: 0;
}

ul,
li {
	padding: 0;
	margin: 0;
	list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	padding: 0;
	margin: 0;
}
.cus-container{
  max-width: 1430px;
  margin: 0 auto;
  padding:0 15px;
}

.btnOutline{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 11px 11px;
  min-width: 182px;
  border: 1px solid #2FB3F0;
  background-color: #fff;
  border-radius: 35px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2FB3F0;
}
.btnOutline:hover{
  background-color: #2FB3F0;
  color: #fff;
}
.boder-tp-none{
  border-top: none !important;
}
.padding-top-0{
  padding-top: 0px !important;
}
.margin-top-0{
  margin-top: 0px !important;
}

/*---------------------- Font Face ------------------*/
/*
font-family: 'Outfit', sans-serif;
*/
/*---------------------- Font Face ------------------*/
.header{
  padding: 0;
  margin: 0 0 0px;
  border-bottom: 1px solid #CED7E5;
  position: relative;
  z-index: 0;
}
.header .header-container{
  max-width: 1430px;
  margin: 0 auto;
  padding: 0 15px;
}

.header .header-container .header-wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

.stellarnav{
  width: auto;
  padding: 0;
  margin: 0;
}
.stellarnav.dark, .stellarnav.dark ul ul {
  background: #fff;
}
.stellarnav>ul>li>a{
  font-weight: 400;
  font-size: 14px;
  line-height:1.2;
  color: #363E48;
  padding: 30px 15px;
  margin: 0;
  transition: all 300ms ease-in-out;
}
.stellarnav>ul>li>a:hover, .stellarnav>ul>li.active a{
  color: #2FB3F0;
  background: #E8F5FB;
}
.stellarnav.dark a, .stellarnav.dark li a {
  color: #363E48;
}
.MuiSelectOutlined .MuiOutlinedInput-notchedOutline {
  border: none;
}
.header .header-container .header-wrap .hd-src{
  position: relative;
  padding: 0;
  margin: 0;
}
.header .header-container .header-wrap .hd-src .src-icon{
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  padding: 0;
  height: 20px;
  margin: auto 0;
  line-height: 0;
}
.header .header-container .header-wrap .hd-src .src-field{
  padding: 10px 10px 10px 40px;
  background: #F2F3F5;
  border-radius: 10px;
  height: 50px;
  min-width:260px;
  font-weight: 400;
  font-size: 14px;
  line-height:1.2;
  color: rgba(54, 62, 72, 0.5);
  border: none;
  outline: none;
  box-shadow: none;
}
.header .header-container .header-wrap .nav-sec{
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.header .header-container .header-wrap .cart-user-info{
  display: flex;
  align-items: center;
  padding:0;
  margin: 0;
}

.header .header-container .header-wrap .cart-user-info .user-info{
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 0;
  background: #F4F4F4;
  border-radius: 70px;
  min-width: 150px;
}
.header .header-container .header-wrap .cart-user-info .user-info strong{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  background: #2FB3F0;
  border-radius: 50%;
  font-weight: 600;
  font-size: 21px;
  line-height:1.2;
  color: #FFFFFF;
  padding: 0;
  margin: 0 10px 0 0;
}
.header .header-container .header-wrap .cart-user-info .user-info p{
  font-weight: 400;
  font-size: 14px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0;
}

.header .header-container .header-wrap .cart-user-info .cart-icon{
  position: relative;
  padding: 0;
  margin: 0 30px 0 0;
}
.header .header-container .header-wrap .cart-user-info .cart-icon span{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 21px;
  background: #2FB3F0;
  border-radius: 11px;
  font-weight: 500;
  font-size: 12px;
  line-height:1.2;
  color: #F4F7FF;
  padding: 0;
  margin: 0;
  position: absolute;
  top: -4px;
  right: -8px;
}

.crypto-select .vodiapicker{
  display: none; 
}

.crypto-select #a{
  padding-left: 0px;
}

.crypto-select #a img, .crypto-select .btn-select img{
  width:20px;
}

.crypto-select #a li{
  list-style: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.crypto-select #a li:hover{
 background-color: #F4F3F3;
}

.crypto-select #a li img{
  margin: 5px;
}

.crypto-select #a li span, .crypto-select .btn-select li span{
  margin-left:5px;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #272B38;
  padding: 0;
}

/* item list */

.crypto-select .b{
  display: none;
  width: 80px;
  /* max-width: 80px; */
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 5px;
  position: absolute;
    background: #fff;
}

.crypto-select .open{
  display: show !important;
}

.crypto-select .btn-select{
  margin-top:0;
  /* width: 100%;
  max-width: 350px; */
  /* height: 34px; */
  border-radius: 5px;
  background-color:transparent;
  border:none;
 
}
.crypto-select .btn-select li{
  list-style: none;
  float: left;
  padding-bottom: 0px;
}

.crypto-select .btn-select:hover li{
  margin-left: 0px;
}

/* .crypto-select .btn-select:hover{
  background-color: #F4F3F3;
  border: 1px solid transparent;
  box-shadow: inset 0 0px 0px 1px #ccc;
} */

.crypto-select .btn-select:focus{
   outline:none;
}

.crypto-select .lang-select{
  margin-left:0;
  position: relative;
}

.card-list-sec{
  padding:30px 0 0px;
  margin: 0;
}
.card-list-sec .card-list-wrap{
  padding: 0;
  margin: 0;
}
.card-list-sec .card-list-wrap .card-item{
  border-radius: 10px;
  overflow: hidden;
}
.card-list-sec .card-list-wrap .card-item img{
  width: 100%;
  height: auto;
}

.cat-sec{
  padding: 40px 0;
  margin: 0;
}
.cat-ttl{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #CED7E5;
  padding: 0 0 30px;
  margin:0 0 30px;
}
.cat-ttl h2{
  font-weight: 500;
  font-size: 22px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0;
}
.cat-ttl.position-center{
  position: relative;
  justify-content: center;
}
.cat-ttl.position-center .cat-filter{
  position: absolute;
  right: 0;
}
.bor-bt-0{
  border-bottom: none;
}

.cat-ttl .cat-filter{
  display: flex;
  padding: 0;
  margin: 0;
}
.cat-ttl .cat-filter .form-control{
  border: 1px solid #CED7E5;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  height: 40px;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #363E48;
}
.cat-sec .cat-panel{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:0px 0 30px;
  margin: 0;
}
.cat-sec .cat-panel h3{
  font-weight: 400;
  font-size: 18px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0;
}
.cat-sec .cat-panel a{
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2FB3F0;
  padding: 0;
  margin: 0;
}

.cat-sec .cat-list-wrap{
  padding: 0 0;
  margin: 0;
}

.cat-box{
  background: #FFFFFF;
  border: 1px solid #CED7E5;
  border-radius: 10px;
  margin: 0 0 30px;
  overflow: hidden;
}
.cat-box .total-panel{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:15px;
  margin: 0;
}
.cat-box .total-panel p{
  font-weight: 300;
  font-size: 14px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0;
}
.cat-box .img-box{
  position: relative;
  padding: 0;
  margin: 0;
}
.cat-box .img-box img{
  width: 100%;
  height: auto;
}
.cat-box .img-box .select-cat{
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 0;
  margin: 0;
  z-index: 2;
}
.cat-box .img-box .select-cat img{
  width: auto;
}
.cat-box.select .img-box .select-cat{
  display: block;
}
.cat-box.select {
  border: 2px solid #2FB3F0;
}
.cat-box .img-box:after{
  content: "";
  width: 100%;
  height: 100%;
  background:transparent;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transition: all 300ms ease-in-out;
}
.cat-box.select .img-box:after{
  background: rgba(0, 0, 0, 0.3);
}
.cat-box .cat-info{
  padding: 15px;
  margin: 0 0 15px;
  border-bottom: 1px solid #CED7E5;
}
.cat-box .cat-info .top-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 15px;
  margin: 0;
}
.cat-box .cat-info .top-bar .panel {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cat-box .cat-info .top-bar .panel .icon{
  padding: 0;
  margin: 0 10px 0 0;
}
.cat-box .cat-info .top-bar .panel .text{
  padding: 0;
  margin: 0;
}
.cat-box .cat-info .top-bar .panel .text p{
  font-weight: 300;
  font-size: 14px;
  line-height:1.2;
  color: #272B38;
  padding: 0;
  margin: 0;
}
.cat-box .cat-info .top-bar .number p{
  font-weight: 300;
  font-size: 14px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0;
}
.cat-box .cat-info a{
  font-weight: 400;
  font-size: 16px;
  line-height:1.2;
  color: #2FB3F0;
  padding: 0;
  margin: 0;
}
.cat-box .bottom-panel{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 15px;
  margin: 0;
}
.cat-box .bottom-panel .btnField button{
  display: inline-block;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.2;
    color: #FFFFFF;
    background: #2FB3F0;
    border-radius: 35px;
    padding: 10px 26px;
    margin: 0;
    transition: all 300ms ease-in-out;
    outline: none;
    box-shadow: none;
    border: 1px solid #2FB3F0;
}
.cat-box .bottom-panel .btnField button.bid{
  background: transparent;
  color: #2FB3F0;
}
.cat-box .bottom-panel .btnField button.closed{
  border-color: #F2F3F5;
  background: #F2F3F5;
  color:#363E48;
}

.cat-box .bottom-panel .lt-side{
  display: flex;
  align-items: center;
}

.cat-box .bottom-panel .lt-side span{
  display: inline-block;
  padding: 0;
  margin: 0 0 0 10px;
}

.src-class-sec{
  padding: 10px 0 40px;
}
.src-class-sec .ttl{
  padding: 0 0 30px;
  margin: 0;
}
.src-class-sec .ttl h2{
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: #000000;
  padding: 0;
  margin: 0;
}

.src-class-box{
  text-align: center;
  padding: 30px 20px;
  margin: 0 0 30px;
  border: 1px solid #CED7E5;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}
.src-class-box .icon{
  text-align: center;
  padding: 0;
  margin: 0 0 20px;
  min-height: 42px;
}
.src-class-box .text-box{
  padding: 0;
  margin: 0;
}
.src-class-box .text-box strong{
  display: block;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #000000;
  padding: 0 0 20px;
  margin: 0;
}
.src-class-box .text-box p{
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #272B38;
  padding: 0;
  margin: 0;
}

.footer{
  padding: 30px 0;
  background: #121820;
}
.footer .footer-wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}
.footer .ft-menu{
  padding: 0;
  margin: 0;
}

.footer .ft-menu ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.footer .ft-menu ul li{
  padding: 0;
  margin: 0 40px 0 0;
}
.footer .ft-menu ul li:last-child{
  margin-right: 0;
}
.footer .ft-menu ul li a{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  padding: 0;
  margin: 0;
  transition: all 300ms ease-in-out;
}
.footer .ft-menu ul li a:hover{
  color: #2FB3F0;
}
.footer .ft-social ul{
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
.footer .ft-social ul li{
  padding: 0;
  margin: 0 10px 0 0;
}
.footer .ft-social ul li:last-child{
  margin-right: 0;
}
.footer .ft-social ul li a{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E2E2E2;
  height: 28px;
  width: 28px;
  border: 1px solid #E2E2E2;
  border-radius: 50%;
  color: #fff;
  transition: all 300ms ease-in-out;
}
.footer .ft-social ul li a:hover{
  color: #2FB3F0;
}


.asset-details-sec{
  padding:30px 0 0;
  margin: 0;
}
.asset-details-sec .asset-details-wrap{
  padding: 0;
  margin: 0;
}
.asset-details-sec .asset-details-wrap .asset-details-info{
  min-height: 497px;
  padding: 0 0 78px;
  position: relative;
}
.asset-details-sec .asset-details-wrap .asset-details-info .details-info-list{
  min-height:calc(100% - 79px);
}
.asset-details-sec .asset-details-wrap .img-box {
  height: 100%;
  border: 1px solid #CED7E5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.asset-details-sec .asset-details-wrap .img-box img{
  max-width: 100%;
  height: auto;
}
.asset-details-sec .asset-details-wrap .ttl h2{ 
  font-weight: 500;
  font-size: 22px;
  line-height:1.2;
  color: #363E48;
  padding: 0 0 30px;
  margin: 0;
}

.asset-details-sec .asset-details-wrap .asset-details-box{
  padding: 0;
  margin: 0 0 10px;
}
.asset-details-sec .asset-details-wrap .asset-details-box span{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(54, 62, 72, 0.5);
  padding: 0;
  margin: 0;
}
.asset-details-sec .asset-details-wrap .asset-details-box p{
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #363E48;
  padding: 0;
  margin: 0;
}


.price-buy-wrap {
  display: flex;
  justify-content: space-between;
  padding:20px 0 0 0;
  margin: 0;
  align-items: center;
  position: absolute;
    width: 100%;
    bottom: 0;
}

.price-buy-wrap .price{
  padding: 0;
  margin: 0;
}
.price-buy-wrap .price .price-box{
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
.price-buy-wrap .price .price-box span{
  font-weight: 500;
  font-size: 24px;
  line-height:1.2;
  color: #272B38;
  padding: 0;
  margin: 0 0 0 10px;
}
.price-buy-wrap .price p{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(54, 62, 72, 0.5);
  padding: 0 0 10px;
  margin: 0;
}
.price-buy-wrap .buy-btn{
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin: 0;
}
.price-buy-wrap .buy-btn button{
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  line-height:1.2;
  color: #FFFFFF;
  background: #2FB3F0;
  border-radius: 35px;
  padding:12px 60px;
  margin: 0;
  transition: all 300ms ease-in-out;
  outline: none;
  box-shadow: none;
  border: none;
}
.price-buy-wrap .buy-btn button.send-btn{
  background: #8497B0;
}
.price-buy-wrap .buy-btn button.sell-btn{
  background: #C00000;
}
.price-buy-wrap .buy-btn button:hover{
  background: #121820;
}

.asset-details-sec .history-table{
  border-top: 1px solid #CED7E5;
  padding:30px 0 30px;
  margin:30px 0 0;
}
.asset-details-sec .history-table h2{
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  padding: 0;
  margin: 0 0 20px;
}
.asset-details-sec .history-table table thead th{
  background: #2FB3F0;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border: none;
  box-shadow: none;
}
.asset-details-sec .history-table table thead tr th:first-child{
  border-radius: 5px 0px 0px 5px;
}
.asset-details-sec .history-table table thead tr th:last-child{
  border-radius: 0px 5px 5px 0px;
}
.asset-details-sec .history-table table tr td{
  border-top: none;
  border-bottom: 1px solid #CED7E5;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #363E48;
}

.selection-list-sec{
  padding: 40px 0;
  margin: 0;
}

.selection-list-sec h2{
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #363E48;
  padding: 0;
  margin: 0 0 20px;
}

.comm-table thead th {
  background: #2FB3F0;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border: none;
  box-shadow: none;
}

.comm-table thead tr th:first-child {
  border-radius: 5px 0px 0px 5px;
}
.comm-table thead tr th:last-child {
  border-radius: 0px 5px 5px 0px;
}
.comm-table tbody tr td {
  border-top: none;
  border-bottom: 1px solid #CED7E5;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #363E48;
  vertical-align: middle;
}
.tb-pro-name{
  display: flex;
  align-items: center;
}
.tb-pro-name .tham{
  width: 101px;
  height: 74px;
}
.tb-pro-name .tham img{
  width: 100%;
  height: 100%;
  object-position: cover;
}
.tb-pro-name p{
  padding: 0 0 0 15px;
  margin: 0;
}

.price-table-info{
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
}
.price-table-info .table-info-wrap{
  max-width: 350px;
  padding: 0;
  margin: 0;
}
.price-table-info .table-info-wrap .table{
  width: 350px;
  margin: 0;
}
.price-table-info .table-info-wrap .table tr td{
  border: none;
}
.price-table-info .table-info-wrap .table tr td span{
  font-size: 16px;
  line-height: 20px;
  color: #363E48;
}
.price-table-info .table-info-wrap .table tr td p{
  color: #2FB3F0;
}
.price-table-info .table-info-wrap .table tr td:last-child, .price-table-info .table-info-wrap .table tr th{
  text-align: right;
}
.price-table-info .table-info-wrap .table tr:first-child td{
  background: #F4F7FF;
}
.price-table-info .table-info-wrap .table.border-non tr:first-child td{
  background: #fff;
}
.table.tb-bg-none tr:first-child td{
  background: transparent !important;
}

.bottom-buy-status-bar{
  padding: 0 0 30px;
}
.bottom-buy-status-bar .buy-status-wrap{
  display: flex;
  flex-wrap: wrap;
  padding:30px 0 0px;
  border-top: 1px solid #ced7e5;
}
.comm-btn{
  display: flex;
  padding: 0;
  margin: 0;
}
.comm-btn button{
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: #FFFFFF;
  background: #2FB3F0;
  border-radius: 35px;
  padding: 12px 60px;
  margin: 0;
  transition: all 300ms ease-in-out;
  outline: none;
  box-shadow: none;
  border: none;
}
.comm-btn button.send-btn{
  background: #8497B0;
}
.comm-btn button.sell-btn{
  background: #C00000;
}


.comm-btn button:hover{
  background: #121820;
}

.details-info-filter-wrap{
  padding:20px 0 0;
}
.comm-srcbar{
  position: relative;
  padding: 0;
  margin: 0 0 20px;
}
.comm-srcbar .form-control{
  height: 50px;
  background: #F2F3F5;
  border-radius: 10px;
  outline: none;
  border: none;
  box-shadow: none;
  padding-left: 40px;
}
.comm-srcbar .icon{
  position: absolute;
  width: 20px;
  height: 20px;
  padding: 0;
  margin:auto 0;
  top: 0;
  bottom: 0;
  left: 10px;
  line-height: 0;
}

.filter-btn-panel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding:10px 20px 10px 10px;
  margin: 0 0 20px;
  border: 1px solid #CED7E5;
  border-radius: 10px;
}
.filter-btn-panel .box{
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  padding: 0;
}
.filter-btn-panel .box .icon{
  width: 45px;
  height: 45px;
  background: #2FB3F0;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-btn-panel .box .text{
  padding: 0 0 0 10px;
  margin: 0;
}
.filter-btn-panel .box .text p{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #363E48;
  padding: 0;
  margin: 0;
}

.sendingUuesrDetails{
  border-top: 1px solid #CED7E5;
  border-bottom: 1px solid #CED7E5;
  padding:15px 0 0;
  margin: 15px 0;
}
.sendingUuesrDetails h4{
  font-weight: 400;
  font-size: 16px;
  line-height:1.2;
  color: rgba(54, 62, 72, 0.5);
  padding: 0;
  margin: 0 0 10px;
}

.sendingUuesrDetails .userInfoPanel{
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 20px;
  margin: 0;
}
.sendingUuesrDetails .userInfoPanel .userInfo{
  display: flex;
  align-items: center;
  padding: 0 60px 0 0;
  margin: 0;
}
.sendingUuesrDetails .userInfoPanel .userInfo span{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  background: #2FB3F0;
  border-radius: 50%;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: #fff;
}
.sendingUuesrDetails .userInfoPanel .userInfo p{
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #363E48;
  padding: 0 0 0 10px;
  margin: 0;
}
.sendingUuesrDetails .userInfoPanel .edit{
   display: flex;
   align-items: center;
   padding: 0;
   margin: 0;
   cursor: pointer;
}
.sendingUuesrDetails .userInfoPanel .edit p{
  font-weight: 400;
  font-size: 16px;
  line-height:1.2;
  color: #2FB3F0;
  padding: 0 0 0 10px;
  margin: 0;
}

.send-nft-price{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.common-price{
  padding: 0;
  margin: 0;
}
.common-price p, .send-nft-price .gasfree p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(54, 62, 72, 0.5);
  padding: 0 0 10px;
  margin: 0;
}

.common-price .price-box {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
.common-price .price-box span {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
  color: #272B38;
  padding: 0;
  margin: 0 0 0 10px;
}

.send-nft-price .gasfree h4{
  font-weight: 300;
  font-size: 16px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0;
}
.cus-border-top{
  border-top: 1px solid #CED7E5;
}

.commModal .MuiDialog-container .MuiPaper-root{
  max-width: 500px;
  width: 100%;
  margin: 0;
  max-height: inherit;
}
.commModal .MuiDialog-container .MuiPaper-root .MuiDialogTitle-root{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #CED7E5;
}
.commModal .MuiDialog-container .MuiPaper-root .MuiDialogTitle-root.no-border-bt{
  border-bottom: none;
}
.commModal .MuiDialog-container .MuiPaper-root .MuiDialogTitle-root span{
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height:1.2;
  color: #2FB3F0;
  padding: 0;
  margin: 0;
  display: inline-block;
}
.commModal .MuiDialog-container .MuiPaper-root .MuiDialogTitle-root .MuiButtonBase-root{
  background: rgba(54, 62, 72, 0.3);
  border: none !important;
  outline: none !important;
}
.commModal .MuiDialog-container .MuiDialogContent-root{
  padding: 0;
  max-height: inherit;
}

.modalAssetImg {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #CED7E5;
}
.modalAssetImg img{
  width: 100%;
  height: auto;
}
.modalAssetInfo{
  padding: 0;
  margin: 0;
}
.modalAssetInfo .assetInfottl{
  padding: 15px 20px 30px;
  margin: 0;
}
.modalAssetInfo .assetInfottl h4{
  font-weight: 400;
  font-size: 22px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0;
}
.modalAssetInfo .assetInfottl p{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #363E48;
  padding: 0;
  margin: 0;
}
.modalAssetInfo .modalPriceInfo{
  padding: 0;
  margin: 0;
}
.modalAssetInfo .modalPriceInfo .panel{
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-top: 1px solid #CED7E5;
}
.modalAssetInfo .modalPriceInfo .panel .ttl p{
  font-weight: 400;
  font-size: 16px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0;
}
.modalAssetInfo .modalPriceInfo .panel .price{
  display: flex;
  align-items: center;
}
.modalAssetInfo .modalPriceInfo .panel .price span{
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height:1.2;
  color: #2FB3F0;
  padding: 0 0 0 10px;
  margin: 0;
}
.modalAssetInfo .modalPriceInfo .panel .price strong{
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
  color: #272B38;
  padding: 0 0 0 10px;
  margin: 0;
}


.modalAssetInfo .modalPriceInfo .panel:last-child{
  border-bottom: 1px solid #CED7E5;
}

.commModal .MuiDialogActions-root{
  padding: 20px;
  margin: 0;
}

.modalBtn{
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  background: #2FB3F0;
  border-radius: 35px;
  padding: 10px 30px;
  outline: none;
  box-shadow: none;
  border: 2px solid #2FB3F0;
  text-transform: capitalize;
}
.modalBtn.hlf-btn{
  width: 49%;
}
.modalBtn.borderBtn{
  color: #2FB3F0;
  background: transparent;
}

.verificationModal{
  padding: 30px 0 45px;
}
.modalImgIcon{
  text-align: center;
  padding: 0;
  margin: 0 0 20px;
}
.modalDesc{
  text-align: center;
  padding: 0 50px 20px;
  margin: 0;
}
.modalDesc p{
  font-weight: 400;
  font-size: 16px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0;
}
.modalTimer{
  text-align: center;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
.modalTimer .modalTimerDesc{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F4F7FF;
  border-radius: 5px;
  padding: 10px 40px;
}
.modalTimer .modalTimerDesc .timeIcon{
  line-height: 0;
}
.modalTimer .modalTimerDesc .timeIcon svg{
  color: #2FB3F0;
}
.modalTimer .modalTimerDesc p{
  font-weight: 400;
  font-size: 14px;
  line-height:1.2;
  color: #363E48;
  padding: 0 0 0 10px;
  margin: 0;
}


.sitePagewrap{
  padding:  30px 0 0;
}
.body-full-height{
  min-height: calc(100vh - 167px);
}
.sitettl{
  padding: 0 0 20px;
}
.sitettl h2{
  font-weight: 400;
  font-size: 18px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0;
}

.sitePagewrap .siteTabhead{
  border-bottom: 1px solid #CED7E5;
}
.sitePagewrap .siteTabhead .MuiTabs-flexContainer{
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;

}
.sitePagewrap .siteTabhead .MuiTabs-flexContainer a{
  padding-left: 0;
  padding-right: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #363E48;
  text-transform: capitalize;
  min-width: inherit;
  max-width: inherit;
  margin-right: 30px;
  font-family: 'Outfit', sans-serif;
}
.sitePagewrap .siteTabhead .MuiTabs-flexContainer a:last-child{
  margin-right: 0;
}

.sitePagewrap .siteTabhead .MuiTabs-flexContainer a.Mui-selected{
  color: #2FB3F0;
}
.sitePagewrap .siteTabhead .MuiTabs-indicator{
  background-color: #2FB3F0;
}

.border-wrap.topBor{
  border-top: 1px solid #ced7e5;
}
.border-wrap.botBor{
  border-bottom: 1px solid #ced7e5;
}
.selection-list-filterbar{
  padding: 0;
  margin: 0;
}
.border-wrap{
  padding: 30px 0;
}

.userInfoPanelEdit {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0px;
  margin: 0;
}
.userInfoPanelEdit .userInfo{
  display: flex;
  align-items: center;
  padding: 0 60px 0 0;
  margin: 0;
}
.userInfoPanelEdit .userInfo span{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  background: #2FB3F0;
  border-radius: 50%;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: #fff;
}
.userInfoPanelEdit .userInfo p{
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #363E48;
  padding: 0 0 0 10px;
  margin: 0;
}
.userInfoPanelEdit .edit{
   display: flex;
   align-items: center;
   padding: 0;
   margin: 0;
   cursor: pointer;
}
.userInfoPanelEdit .edit p{
  font-weight: 400;
  font-size: 16px;
  line-height:1.2;
  color: #2FB3F0;
  padding: 0 0 0 10px;
  margin: 0;
}


.sin-asset-details-info {
  padding: 0 0 78px;
  position: relative;
}

.sing-asset-details-box{
  padding: 0;
  margin: 0;
}
.sing-asset-details-box span{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(54, 62, 72, 0.5);
  padding: 0;
  margin: 0;

}
.sing-asset-details-box p{
  font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #363E48;
    padding: 0;
    margin: 0;
}
.price-buy-wrap.position-none{
  position: relative;
}
.asset-tabheader .MuiTabs-flexContainer{
  border-bottom: 1px solid #ced7e5;
}
.asset-tabheader .MuiTabs-flexContainer a{
  padding: 10px 20px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height:1.2;
  color: #363E48;
  text-transform: capitalize;
}
.asset-tabheader .MuiTabs-flexContainer a:hover, .asset-tabheader .MuiTabs-flexContainer a.Mui-selected {
  background: #E8F5FB;
  color: #2FB3F0;
}
.asset-tabheader .MuiTabs-indicator{
  display: none;
}

.cus-border-bottom{
  border-bottom: 1px solid #ced7e5;
}

.selling-form{
  padding: 0;
  margin: 0;
}
.selling-form label{
  font-weight: 400;
  font-size: 16px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0 0 6px;
}
.selling-form .form-control{
  background: #F2F3F5;
  border-radius: 10px;
  height: 50px;
  border: none;
  box-shadow: none;
  outline: none;
}
.cost-destilas{
  display: flex;
}
.cost-destilas .info ul li{
  text-align: right;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(54, 62, 72, 0.5);
  margin: 0 0 8px;
}
.cost-destilas .info ul li:last-child{
  margin-bottom: 0;
}
.cost-destilas .info ul li span{
  color: #363E48;
}
.comm-gass-free{
  padding: 0;
  margin: 0;
}
.comm-gass-free span{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(54, 62, 72, 0.5);
  padding: 0;
  margin: 0;
}
.comm-gass-free p{
  font-weight: 300;
font-size: 16px;
line-height: 20px;
color: #363E48;
padding: 0;
margin: 0;
}
span.tb-price {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
span.tb-price img{
  margin: 0 8px 0 0;
}

.tb-form-field{
  padding: 0;
  margin: 0;
  position: relative;
}
.tb-form-field .form-control{
  border: none;
  box-shadow: none;
  background: #F2F3F5;
  border-radius: 10px;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height:1.2;
  color: #363E48;
}
.tb-form-field .form-control.ex-pl{
  padding-left: 40px;
}
.tb-form-field img.play-icon{
  position: absolute;
  height: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 10px;
}

.tb-price-wrap.bor-cell {
  background: #FFFFFF;
  border: 1px solid #2FB3F0;
  border-radius: 10px;
  padding: 10px;
}
.tb-price-wrap.bg-cell {
  background: #F2F3F5;
  border-radius: 10px;
  padding: 10px;
}
span.tb-price em{
  font-style: normal;
  color: #2FB3F0;
}


.history-panel {
  display: flex;
  background: #FFFFFF;
  border: 1px solid #CED7E5;
  border-radius: 5px;
  flex-wrap: wrap;
  padding: 20px 15px;
  margin: 0 0 10px;
}
.history-panel .box{
  width: 16.666666%;
}
.history-panel .box span{
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height:1.2;
  color: rgba(54, 62, 72, 0.5);
  padding: 0;
  margin: 0 0 8px;
}
.history-panel .box p{
  font-weight: 300;
  font-size: 16px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0;
}
.history-panel .box .btn{
  font-weight: 300;
  font-size: 16px;
  line-height:1.2;
  color: #2FB3F0;
  background: #E8F5FB;
  padding: 10px;
  outline: none;
  box-shadow: none;
  border: none;
}
.no-border-tp{
  border-top: none !important;
}
.no-border-b{
  border-bottom: none !important;
}

.creatMinting{
  padding:30px 0;
  margin: 0;
}
.creatMinting .creat-box{
  padding: 0;
  margin: 0;
  max-width: 500px;
}
.creatMinting .creat-box h2{
  font-weight: 500;
  font-size: 22px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0 0 15px;
}
.creatMinting .creat-box p{
  font-weight: 300;
  font-size: 16px;
  line-height:1.2;
  padding: 0;
  margin: 0 0 15px;
  color: #363E48;

}
.creatMinting .creat-box p sup{
  color: #CE5555;
}
.creatMinting .creat-box strong{
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0 0 20px;
}
.creatMinting .creat-box .cus-upload{
  padding: 0;
  margin: 0;
}
.creatMinting .creat-box .cus-upload input{
  display: none;
}
.creatMinting .creat-box .cus-upload label {
  width: 100%;
  height: 217px;
  background: #F2F3F5;
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.creatMinting .creat-box .cus-upload label:after{
  content: '';
  width: 96%;
  height: 90%;
  border: 1px dashed #96A0AE;
  border-radius: 8px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin: auto;
}
.creatMinting .creat-box .cus-upload p {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #363E48;
  padding: 0;
  margin: 0;
  max-width: 350px;
}
.creatMinting .creat-box .cus-upload p span{
  display: block;
}

.commonfr label{
  font-weight: 500;
  font-size: 16px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0 0 6px;
}
.commonfr label sup{
  color: #CE5555;
}
.commonfr .form-group p{
  font-weight: 300;
  font-size: 14px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0 0 6px;
}
.commonfr .form-control{
  background: #F2F3F5;
  border-radius: 10px;
  height: 45px;
  border: none;
  outline: none;
  box-shadow: none;
}
.commonfr .btn{
  background: #2FB3F0;
  border-radius: 35px;
  font-weight: 600;
  font-size: 16px;
  line-height:1.2;
  color: #FFFFFF;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 14px 60px;
}

.commModal .searchid-wrap {
  padding: 10px 20px;
  margin: 0;
}
.commModal .searchid-wrap .search-field{
  position: relative;
  padding: 0;
  margin: 0 0 15px;
}
.commModal .searchid-wrap .search-field .form-control{
  padding: 10px 40px 10px 15px;
  background: #F2F3F5;
  border-radius: 10px;
  border: none;
  box-shadow: none;
  outline: none;
  height: 46px;
}
.commModal .searchid-wrap .search-field .btn{
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  margin: auto 0;
  outline: none;
  box-shadow: none;
  border-radius: 0;
  line-height: 1.2;
  height:46px;
  display: flex;
  align-items: center;
  padding: 0 9px;
}

.commModal .searchid-wrap .search-field .btn img{ 
  max-width: 100%;
}
.commModal .searchid-wrap p{
  font-weight: 400;
  font-size: 14px;
  line-height:1.2;
  color: rgba(54, 62, 72, 0.8);
  padding: 0;
  margin: 0 0 10px;
}
.commModal .searchid-wrap strong{
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0 0 10px;
}
.commModal .MuiFormControl-root{
  width: 100%;
}
.commModal .MuiFormControl-root label {
  justify-content: space-between;
  padding: 6px 0 6px 10px;
  margin: 0;
  background: rgba(47, 179, 240, 0.1);
  border-bottom: 1px solid #CED7E5;
}
.commModal .MuiFormControl-root label:first-child{
  border-top: 1px solid #CED7E5;
}
.commModal .src-list {
  min-height: 350px;
  max-height: 350px;
  overflow-y: auto;
}

.commModal .recent-add-wrap{
  padding: 0 20px;
}
.commModal .recent-add-wrap h4{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #363E48;
  padding:15px;
  margin: 0;
}

.commModal .src-list::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.commModal .src-list::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.commModal .src-list::-webkit-scrollbar-thumb
{
	background-color: #2FB3F0;
}
.insufficient-balance-panel {
  display: flex;
  align-items: center;
  background: #EBB42E;
  padding: 15px;
}
.insufficient-balance-panel p{
  font-weight: 400;
  font-size: 14px;
  line-height:1.2;
  color: #FFFFFF;
  padding: 0 0 0 10px;
  margin: 0;
}

.transaction-block {
  padding: 20px 20px 0;
  margin: 0;
}

.transaction-block .icon-box{
  text-align: center;
  padding: 0;
  margin: 0 0 15px;
}
.transaction-block .text-block{
  text-align: center;
  padding: 0;
  margin: 0 0 30px;
}
.transaction-block .text-block h3{
  font-weight: 400;
  font-size: 22px;
  line-height: 1.2;
  color: #363E48;
  padding: 0;
  margin: 0 0 10px;
}
.transaction-block .text-block p{
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #363E48;
  padding: 0;
  margin: 0;
}
.transaction-block .transaction-list-wrap{
  padding: 0 30px 15px;
}
.transaction-block .transaction-list-wrap .transaction-list-panel{
  display: flex;
  align-items: center;
  padding:15px;
  margin: 0 0 15px;
  border: 1px solid #CED7E5;
  border-radius: 5px;
}
.transaction-block .transaction-list-wrap .transaction-list-panel:last-child{
  margin-bottom: 0;
}
.transaction-block .transaction-list-wrap .transaction-list-panel .tham{
  width: 45px;
  height: 45px;
}
.transaction-block .transaction-list-wrap .transaction-list-panel .tham img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.transaction-block .transaction-list-wrap .transaction-list-panel .text{
  padding: 0 0 0 20px;
  margin: 0;
}
.transaction-block .transaction-list-wrap .transaction-list-panel .text p{
  font-weight: 400;
  font-size: 18px;
  line-height:1.2;
  color: #363E48;
  padding: 0;
  margin: 0;
}

.select-item-bar {
  background: #FFFFFF;
  box-shadow: 0px -7px 24px rgba(0, 0, 0, 0.07);
  padding: 20px 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 2;
  left: 0;
}

.select-item-bar .select-item-wrap{
  position: relative;
  padding: 0 200px;
  margin: 0;
}
.select-item-bar .select-item-wrap .item-select {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0px, -50%);
}
.select-item-bar .select-item-wrap .item-select .icon{
  position: relative;
}

.select-item-bar .select-item-wrap .item-select .icon .select-tool {
  position: absolute;
  top: -51px;
  width: 73px;
  left: -24px;
}

.select-item-bar .select-item-wrap .item-select .icon .select-tool .tool-wrap {
  background: #44546A;
  border-radius: 3px;
  text-align: center;
  padding: 9px 5px 10px;
  position: relative;
}
.select-item-bar .select-item-wrap .item-select .icon .select-tool .tool-wrap::after {
  content: '';
  width: 14px;
  height: 14px;
  background: #44546A;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -7px;
  transform: rotate(45deg);
}
.select-item-bar .select-item-wrap .item-select .icon .select-tool .tool-wrap p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  padding: 0;
  margin: 0;
}


.cart-modal-sec .MuiDialog-container .MuiPaper-root {
    max-width:750px;
    width: 100%;
    margin: 0;
    max-height: inherit;
}
.cart-modal-sec .price-table-info{
  justify-content: space-between;
}
.cart-modal-sec .price-table-info .table-info-wrap {
  max-width: 300px;
}
.cart-modal-sec .price-table-info .table-info-wrap .table{
  width: 100%;
}

.ConnectWalletList {
  padding: 20px 20px 0;
  margin: 0;
}
.ConnectWalletList .ConnectWalletpanel{
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #CED7E5;
  border-radius: 6px;
  margin: 0 0 10px;
}
.ConnectWalletList .ConnectWalletpanel .info{
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
.ConnectWalletList .ConnectWalletpanel .info .icon{
  width: 40px;
  height: 40px;
  background: #F2F3F5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ConnectWalletList .ConnectWalletpanel .info .text{
  padding: 0 0 0 15px;
  margin: 0;
}
.ConnectWalletList .ConnectWalletpanel .info .text p{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272B38;
  padding: 0;
  margin: 0;
}

.WalletModal .qr-img{
  text-align: center;
  padding: 20px 25px;
  margin: 0;
}
.WalletModal .qr-img img{
  max-width: 100%;
  height: auto;
}
.WalletModal .timing-block{
  text-align: center;
  padding: 0px 15px 30px;
}
.WalletModal .timing-block p{
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #272B38;
  padding: 0;
  margin: 0;
}
.WalletModal .timing-block p span{
  color: #2FB3F0;
}
.qr-modal-footer{
  background: #2FB3F0;
  padding: 30px 15px 40px;
}
.qr-modal-footer .step-box{
  text-align: center;
  padding: 0;
  margin: 0;
}
.qr-modal-footer .step-box strong{
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  padding: 0;
  margin: 0 0 10px;
}
.qr-modal-footer .step-box .icon{
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
}
.qr-modal-footer .step-box p{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  padding: 0;
  margin: 0;
}
.commModal.WalletModal .MuiDialog-container .MuiPaper-root .MuiDialogTitle-root span{
  color: #363E48;
}
.commModal.WalletModal .MuiDialog-container .MuiPaper-root .MuiDialogTitle-root{
  border-bottom: none;
}


.qty {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.qty label {
  flex: 1 0 100%;
}
.qty input {
  width: 40px;
  height: 25px;
  font-size:16px;
  line-height: 1.2;
  text-align: center;
  border: 1px solid #ccc;
  outline: none;
  box-shadow: none;
  text-align: center;
}
.qty input::-webkit-outer-spin-button,
.qty input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.qty input[type=number] {
  -moz-appearance: textfield;
}


.qty button {
  width: 25px;
  height: 25px;
  color: #fff;
  font-size: 14px;
  background: #2FB3F0;
  border: none;
  outline: none;
  box-shadow: none;
  text-align: center;
}
.qty button.qtyminus {
  margin-right:0;
}
.qty button.qtyplus {
  margin-left:0;
}

.priceBtnGroup{
  position: relative;
  margin-right: 24px;
}
.priceBtnGroup input{
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  padding: 16px 110px 16px 16px;
  height: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(54, 62, 72, 0.5);
}
.priceBtnGroup input:focus{
  box-shadow: none;
}
.priceBtnGroup select{
  position: absolute;
  right: 1px;
  top: 1px;
  height: 48px;
  background: #F2F3F5;
  border-radius: 0px 10px 10px 0px;
  border: none;
  padding: 0 20px 0 20px;
}
.priceBtnGroup p{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(54, 62, 72, 0.8);
  margin: 7px 0 0;
  text-align: right;
}

.modalAssetInfo .modalPriceInfo .panel.durationPanel{
  flex-wrap: wrap;
}
.modalAssetInfo .modalPriceInfo .panel .durationDiv{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 0 0;
}
.modalAssetInfo .modalPriceInfo .panel .durationDiv .left {
  width: calc(100% - 220px);
}
.modalAssetInfo .modalPriceInfo .panel .durationDiv .left select{
  background: #F2F3F5;
  border-radius: 10px;
  padding: 16px 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #363E48;
  border: none;
  min-width: 120px;
  outline: none;
}
.modalAssetInfo .modalPriceInfo .panel .durationDiv .right{
  background: #F2F3F5;
  border-radius: 10px;
  padding: 16px 20px;
  display: flex;
  width: 100%;
  max-width: 220px;
}
.modalAssetInfo .modalPriceInfo .panel .durationDiv .right .date{
  margin-right: 36px;
}
.price-buy-wrap-auctionBuy{
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
}
.price-buy-wrap-auctionBuy .price{
  margin-right: 8px;
}
.price-buy-wrap-auctionBuy .price:last-child{
  margin-right: 0px;
}

.creation-text{
  color: #2FB3F0;
  cursor: pointer;
}

.creation-view .MuiPaper-root.MuiPaper-elevation {
  max-width: 1250px;
}